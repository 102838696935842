<template>
  <div class="flex items-center justify-between mb-5 space-x-6">
    <h2 class="w-full max-w-xs font-medium text-black-900" :class="titleClass">
      {{ title }}
    </h2>
    <div class="flex items-center justify-end flex-1">
      <button
        v-if="
          isAuthenticated &&
          exportButton &&
          (user.type === 'admin' || user.type === 'reseller')
        "
        @click="exportToPdf"
        type="button"
        class="
          bg-orange-900
          border border-orange-900
          text-white-900
          rounded
          text-base
          font-medium
          px-4
          py-2.5
          hover:bg-white-900 hover:text-orange-900
        "
      >
        Export to PDF
      </button>
      <div class="flex items-center justify-end flex-grow space-x-20">
        <base-select
          class="flex-shrink-0"
          :selectList="sortByList"
          :value="sortByValue"
          @input="$emit('handleSortByChange', $event)"
        />
        <search-input
          :placeholder="placeholder"
          big
          :value="searchValue"
          @input="$emit('handleSearchValueChange', $event)"
          @handleEnter="$emit('handleSearch')"
          @handleClear="$emit('handleSearchClear')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ActionsBar",
  components: {
    "base-select": () => import("@/components/utils/Select.vue"),
    "search-input": () => import("@/components/utils/SearchInput.vue"),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    exportButton: {
      type: Boolean,
      default: false,
    },
    sortByType: {
      type: String,
      required: true,
    },
    sortByValue: {
      type: String,
      required: true,
    },
    hasSortByRelevance: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    sortByList() {
      let list = [];
      switch (this.sortByType) {
        case "books":
          list = [
            { code: "latest-update", label: "Latest updates" },
            { code: "popular", label: "Popular" },
            { code: "name-asc", label: "Name" },
          ];
          break;
        case "publishers":
          list = [
            { code: "name-asc", label: "Name" },
            { code: "book-count-desc", label: "Number of books" },
          ];
          break;
        case "catalogs":
          list = [
            { code: "latest-update", label: "Latest updates" },
            { code: "newest", label: "Date added" },
            { code: "popular", label: "Popular" },
            { code: "name-asc", label: "Name" },
          ];
          break;
      }
      if (this.hasSortByRelevance) {
        list.unshift({ code: "", label: "Relevance" });
      }
      return list;
    },
  },
  methods: {
    exportToPdf() {
      this.$emit("export-to-pdf");
    },
  },
};
</script>
