<template>
  <div class="about">
    <div class="mt-4 bg-white-900 mb-14">
      <div class="container mx-auto">
        <actions-bar
          title="Publishers"
          title-class="text-5xl"
          placeholder="Search by name"
          sortByType="publishers"
          :sortByValue="sortBy"
          :searchValue="searchValue"
          :hasSortByRelevance="hasSortByRelevance"
          @handleSortByChange="handleSortByChange"
          @handleSearchValueChange="searchValue = $event"
          @handleSearch="handleSearch"
          @handleSearchClear="handleSearchClear"
        />
        <div v-if="isPublishersLoading">Loading publishers...</div>
        <template v-else>
          <card-publisher-summary
            v-for="publisher in publishers"
            :key="publisher.id"
            :publisher-data="publisher"
            class="mb-3"
            @open-book-modal="openBookModal"
          />
        </template>

        <base-button
          v-if="publishersCount - publishers.length > 0"
          class="mt-6"
          @click.native="handleFetchMore"
        >
          <svg
            class="mr-2"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 1V11M11 6L1 6"
              stroke="currentColor"
              stroke-width="1.6"
              stroke-linecap="round"
            />
          </svg>
          Show more {{ publishersCount - publishers.length }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CardPublisherSummary from "@/components/cards/CardPublisherSummary.vue";
import ActionsBar from "@/components/base/ActionsBar.vue";
import {
  FETCH_PUBLISHERS,
  FETCH_MORE_PUBLISHERS,
  UPDATE_BOOK_BASE_PAGE,
} from "@/store/actions.type";
import {
  RELEVANCE_SORT_BY,
  DEFAULT_PUBLISHERS_SORT_BY,
} from "@/common/constants";

export default {
  name: "Publishers",
  components: {
    "actions-bar": ActionsBar,
    "card-publisher-summary": CardPublisherSummary,
    "base-button": () => import("@/components/utils/Button.vue"),
  },
  mounted() {
    this.fetchPublishers();
  },
  data() {
    return {
      searchValue: this.$route.query.q ? this.$route.query.q : "",
      sortBy: this.$route.query.s
        ? this.$route.query.s
        : this.$route.query.q
        ? RELEVANCE_SORT_BY
        : DEFAULT_PUBLISHERS_SORT_BY,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(["isPublishersLoading", "publishers", "publishersCount"]),
    hasSortByRelevance() {
      return this.$route.query.q && this.$route.query.q.length > 0;
    },
  },
  methods: {
    getQueryParams() {
      return { q: this.searchValue, s: this.sortBy };
    },
    updateQueryParams(isUpdateQuery) {
      let query = {};

      const q = isUpdateQuery ? this.searchValue : this.$route.query.q;
      if (q && q.length > 0) {
        query["q"] = q;
      }

      if (this.sortBy.length > 0) {
        query["s"] = this.sortBy;
      }

      this.$router.push({
        name: "publishers",
        query,
      });
    },
    fetchPublishers() {
      this.$store.dispatch(FETCH_PUBLISHERS, this.getQueryParams());
    },
    handleSortByChange(event) {
      this.sortBy = event;
      this.updateQueryParams(false);
    },
    handleSearch() {
      if (this.searchValue.length > 0) {
        this.sortBy = RELEVANCE_SORT_BY;
      } else {
        this.sortBy = DEFAULT_PUBLISHERS_SORT_BY;
      }
      this.updateQueryParams(true);
    },
    handleSearchClear() {
      this.searchValue = "";
      this.sortBy = DEFAULT_PUBLISHERS_SORT_BY;
      this.updateQueryParams(true);
    },
    handleFetchMore() {
      this.page++;
      this.$store.dispatch(FETCH_MORE_PUBLISHERS, {
        ...this.getQueryParams(),
        p: this.page,
      });
    },
    openBookModal(handle) {
      this.$store.dispatch(UPDATE_BOOK_BASE_PAGE, {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      });
      this.$router.push({ name: "book", params: { handle } });
    },
  },
};
</script>
