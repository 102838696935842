<template>
  <div class="w-212" @click.stop>
    <h3 class="text-grey-700 text-base font-medium uppercase mb-4">
      {{ title }}
    </h3>
    <VueSlickCarousel
      v-bind="settings"
      :ref="sliderName"
      class="mb-2"
      @beforeChange="changeCurrentSlide"
    >
      <div v-for="item in itemsList" :key="item.id" class="mb-3">
        <a
          :href="
            type === 'catalogs'
              ? $router.resolve({
                  name: 'catalog',
                  params: { handle: item.handle },
                }).href
              : $router.resolve({
                  name: 'book',
                  params: { handle: item.handle },
                }).href
          "
          class="flex items-start cursor-pointer"
          @click.prevent="$emit('open-catalog-or-book', item)"
        >
          <custom-image
            :src="item.imageUrl"
            imgClass="filter drop-shadow-md mr-3"
            :width="40"
          />
          <div>
            <h4 class="text-black-900 text-sm font-medium mb-2 capitalize">
              {{ item.name }}
            </h4>
            <span
              v-if="type === 'catalogs'"
              class="
                flex
                items-center
                text-grey-700 text-sm
                uppercase
                font-medium
              "
            >
              <svg
                class="mr-1.5"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
                  fill="currentColor"
                />
              </svg>

              {{ item.bookCount }} titles
            </span>
            <div v-else>
              <h5
                v-for="(author, index) in item.author ? item.author : []"
                :key="index"
                class="text-grey-700 text-sm uppercase font-medium line-clamp-2"
              >
                <span
                  class="hover:underline"
                  @click.stop.prevent="() => handleAuthorClick(author)"
                  >{{ author }}</span
                >
                <span v-if="index + 1 < item.author.length">, </span>
              </h5>
            </div>
          </div>
        </a>
      </div>
    </VueSlickCarousel>
    <slider-pagination
      :slider-length="paginationCounts"
      :current-index="currentSlide"
      @go-to-slide="goToSlide"
    ></slider-pagination>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import SliderPagination from "./SliderPagination.vue";
import CustomImage from "@/components/utils/CustomImage.vue";

export default {
  name: "DoubleSlider",
  components: {
    VueSlickCarousel,
    SliderPagination,
    CustomImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    sliderName: {
      type: String,
      required: true,
    },
    itemsList: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        arrows: false,
        slidesToShow: 1,
        rows: 2,
        slidesPerRow: 1,
      },
      currentSlide: 0,
    };
  },
  computed: {
    paginationCounts() {
      return Math.floor(this.itemsList.length / 2) + 1;
    },
  },
  methods: {
    goToSlide(i) {
      this.$refs[this.sliderName].goTo(i);
    },
    changeCurrentSlide(oldSlideIndex, newSlideIndex) {
      this.currentSlide = newSlideIndex;
    },
    handleAuthorClick(author) {
      this.$router.push({
        name: "search",
        query: {
          q: author.toLowerCase(),
          fa: encodeURIComponent(JSON.stringify([author])),
        },
      });
    },
  },
};
</script>
